<template>
  <div class="totalCmpt">
    <div>
      <div class="navBar">
        <div class="nav-item" ref="nav1" @click="toPage(1)">
          <el-image ref="nav1" :src="ossHost + 'user.png'"></el-image>
        </div>
        <div class="nav-item" ref="nav2" @click="toPage(2)">
          <el-image :src="ossHost + 'shuiwu.png'"></el-image>
        </div>
        <div class="nav-item" ref="nav5" @click="toPage(5)">
          <el-image :src="ossHost + 'yundans.png'"></el-image>
        </div>
        <div class="nav-item" ref="nav3" @click="toPage(3)">
          <el-image :src="ossHost + 'zijin.png'"></el-image>
        </div>
        <div class="nav-item" ref="nav4" @click="toPage(4)">
          <el-image :src="ossHost + 'qiye.png'"></el-image>
        </div>
      </div>

      <el-carousel
        indicator-position="none"
        height="260px"
        :interval="5000"
        arrow="always"
      >
        <el-carousel-item>
          <div class="middle-box">
            <!--司机认证状态-->
            <div class="top">
              <div class="title">司机认证状态</div>
              <div class="pieData">
                <PieChart
                  class="pieChart"
                  :data="driverData"
                  :id="'pie1'"
                  :total="driverTotal"
                  :color="['#0F5FFF', '#FF2323', '#00E855', '#FF8B17']"
                />
                <div class="tip">
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'siji1.png'"
                    ></el-image>
                    <span
                      >未认证：{{
                        driverData[0].Val ? driverData[0].Val : "0"
                      }}人</span
                    >
                  </div>
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'siji2.png'"
                    ></el-image>
                    <span
                      >未通过：{{
                        driverData[1].Val ? driverData[1].Val : "0"
                      }}人</span
                    >
                  </div>
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'siji4.png'"
                    ></el-image>
                    <span
                      >认证中：{{
                        driverData[3].Val ? driverData[3].Val : "0"
                      }}人</span
                    >
                  </div>
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'siji3.png'"
                    ></el-image>
                    <span
                      >已认证：{{
                        driverData[2].Val ? driverData[2].Val : "0"
                      }}人</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!--车辆认证状态-->
            <div class="bottom">
              <div class="title">车辆认证状态</div>
              <div class="pieData">
                <PieChart
                  class="pieChart"
                  :data="carData"
                  :id="'pie2'"
                  :total="carTotal"
                  :color="['#0F5FFF', '#FF2323', '#00E855', '#FF8B17']"
                />
                <div class="tip">
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'huoche1.png'"
                    ></el-image>
                    <span
                      >未认证：{{
                        carData[0].Val ? carData[0].Val : "0"
                      }}辆</span
                    >
                  </div>
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'huoche3.png'"
                    ></el-image>
                    <span
                      >未通过：{{
                        carData[1].Val ? carData[1].Val : "0"
                      }}辆</span
                    >
                  </div>
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'huoche2.png'"
                    ></el-image>
                    <span
                      >认证中：{{
                        carData[3].Val ? carData[3].Val : "0"
                      }}辆</span
                    >
                  </div>
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'huoche4.png'"
                    ></el-image>
                    <span
                      >已认证：{{
                        carData[2].Val ? carData[2].Val : "0"
                      }}辆</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!--历史数据-->
            <!-- <div class="historyData">
          <div class="title">历史数据</div>
          <div class="data">
            <div class="items" style="margin-top: 16px;">
              <div class="name">运单数量(条):</div>
              <div class="num">
                {{
                    historyData.WaybillCount ? historyData.WaybillCount : "0"
                }}
              </div>

            </div>
            <div class="items">
              <div class="name">总税费(元):</div>
              <div class="num">
                {{
                    historyData.sumExpensesOfTaxation
                      ? '￥' + historyData.sumExpensesOfTaxation
                      : "0"
                }}
              </div>

            </div>
            <div class="items">
              <div class="name">总运费(元):</div>

              <div class="num">
                {{
                    historyData.sumTransportCosts
                      ? '￥' + historyData.sumTransportCosts
                      : "0"
                }}
              </div>
            </div>
            <div class="items">
              <div class="name">总支付金额(元):</div>

              <div class="num">
                {{
                    historyData.sumNeedPayMoney
                      ? '￥' + historyData.sumNeedPayMoney
                      : "0"
                }}
              </div>
            </div>

          </div>
        </div> -->
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="middle-box">
            <!--船东认证状态-->
            <div class="top">
              <div class="title">船东认证状态</div>
              <div class="pieData">
                <PieChart
                  class="pieChart"
                  :data="shipOwnerData"
                  :id="'pie3'"
                  :total="shipOwnerTotal"
                  :color="['#0F5FFF', '#FF2323', '#00E855', '#FF8B17']"
                />
                <div class="tip">
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'huoche1.png'"
                    ></el-image>
                    <span
                      >未认证：{{
                        shipOwnerData[0].Val ? shipOwnerData[0].Val : "0"
                      }}人</span
                    >
                  </div>
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'huoche3.png'"
                    ></el-image>
                    <span
                      >未通过：{{
                        shipOwnerData[1].Val ? shipOwnerData[1].Val : "0"
                      }}人</span
                    >
                  </div>
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'huoche2.png'"
                    ></el-image>
                    <span
                      >认证中：{{
                        shipOwnerData[3].Val ? shipOwnerData[3].Val : "0"
                      }}人</span
                    >
                  </div>
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'huoche4.png'"
                    ></el-image>
                    <span
                      >已认证：{{
                        shipOwnerData[2].Val ? shipOwnerData[2].Val : "0"
                      }}人</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <!--船舶认证状态-->
            <div class="bottom">
              <div class="title">船舶认证状态</div>
              <div class="pieData">
                <PieChart
                  class="pieChart"
                  :data="shipData"
                  :id="'pie4'"
                  :total="shipTotal"
                  :color="['#0F5FFF', '#FF2323', '#00E855', '#FF8B17']"
                />
                <div class="tip">
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'huoche1.png'"
                    ></el-image>
                    <span
                      >未认证：{{
                        shipData[0].Val ? shipData[0].Val : "0"
                      }}艘</span
                    >
                  </div>
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'huoche3.png'"
                    ></el-image>
                    <span
                      >未通过：{{
                        shipData[1].Val ? shipData[1].Val : "0"
                      }}艘</span
                    >
                  </div>
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'huoche2.png'"
                    ></el-image>
                    <span
                      >认证中：{{
                        shipData[3].Val ? shipData[3].Val : "0"
                      }}艘</span
                    >
                  </div>
                  <div class="car">
                    <el-image
                      class="img"
                      :src="ossHost + 'huoche4.png'"
                    ></el-image>
                    <span
                      >已认证：{{
                        shipData[2].Val ? shipData[2].Val : "0"
                      }}艘</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>

      <div class="content">
        <div class="graphic">
          <div
            style="
              display: flex;
              align-items: center;
              border-bottom: 1px solid #cbcbcb;
              padding-bottom: 8px;
              padding-top: 1rem;
            "
          >
            <div class="title">交易金额统计</div>
            <div class="invoice-tabs">
              <div
                :class="invoiceCode == item.Code ? 'isActive' : ''"
                @click="selectInvoice(item.Code)"
                v-for="item in invoiceList"
                :key="item.Code"
              >
                {{ item.Name }}
              </div>
            </div>
          </div>
          <div class="invoice-tabs" style="margin-bottom: 8px">
            <div
              style="margin: 8px 8px 0 0; font-weight: 400"
              :class="sceneCode == item.Code ? 'isActive' : ''"
              @click="selectScene(item.Code)"
              v-for="item in sceneList"
              :key="item.Code"
            >
              {{ item.Name }}
            </div>
          </div>
          <div class="time">
            <!-- <span @click="tabChange(item.id)" :class="timeActive == item.id ? 'active' : ''" v-for="item in timeList" :key="item.id">{{ item.name }}</span> -->
            <el-radio-group size="mini" v-model="radio" @change="tabChange">
              <el-radio-button
                v-for="item in timeList"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <div class="tu">
            <LineChart class="chart" :id="'line1'" :InvoiceData="InvoiceData" />
          </div>
        </div>
      </div>
    </div>
    <!--首页消息强提醒弹框-->
    <!-- <HomeTip />-->
  </div>
</template>

<script>
import LineChart from "../../../../components/chart/lineChart.vue";
import PieChart from "../../../../components/chart/pieChart.vue";
import { taskSummary } from "@/api/finance/account/index";
import ZXmap from "@/components/commonCmpt/ZXmap";
import { _formatMoney } from "@/utils/utils";
import { _getIsAdmin } from "@/utils/storage";
import basicMixin from "@/mixins/basic";
import {
  getSceneType,
  getDriverNum,
  getVehicleNum,
  getInvioceReport,
  getShipOwnerNum,
  getShipNum,
} from "@/api/home/statistic";
export default {
  mixins: [basicMixin],
  data() {
    return {
      timeActive: 0, // 时间tab切换
      timeList: [
        {
          name: "过去一周",
          id: 0,
        },
        {
          name: "过去一个月",
          id: 1,
        },
        {
          name: "过去三个月",
          id: 2,
        },
      ],
      receiptActive: 1,
      InvoiceData: [], // 发票数据
      driverData: [
        {
          Val: "",
        },
        {
          Val: "",
        },
        {
          Val: "",
        },
        {
          Val: "",
        },
      ], // 司机数据
      carData: [
        {
          Val: "",
        },
        {
          Val: "",
        },
        {
          Val: "",
        },
        {
          Val: "",
        },
      ], // 车辆数据
      shipOwnerData: [
        {
          Val: "",
        },
        {
          Val: "",
        },
        {
          Val: "",
        },
        {
          Val: "",
        },
      ],
      shipData: [
        {
          Val: "",
        },
        {
          Val: "",
        },
        {
          Val: "",
        },
        {
          Val: "",
        },
      ],
      carTotal: 0,
      driverTotal: 0,
      shipOwnerTotal: 0,
      shipTotal: 0,
      historyData: {}, // 历史数据
      radio: 0, // 切换日期选项
      invoiceList: [], // 发票类型
      invoiceCode: "01", // 选中的发票类型
      sceneList: [], // 发票类型下的业务场景
      sceneCode: "", // 选中的场景类型
    };
  },
  created() {
    //历史总数据
    this.taskSummary();
    this.getVehicleNum();
    this.getDriverNum();
    this.getSceneType();
    this.getShipOwnerNum();
    this.getShipNum();
  },
  mounted() {
    // this.getCount();
    //this.getArr()
  },
  methods: {
    // 切换时间tab
    tabChange(id) {
      this.timeActive = id;
      this.getInvoiceData();
    },
    // 获取发票统计数据
    getInvoiceData() {
      let params = {
        dateType: this.timeActive,
        taskSceneType: this.sceneCode,
        sourceId: "", // 总计
      };

      getInvioceReport(params).then((res) => {
        this.InvoiceData = res.data;
      });
    },
    // 选择发票类型
    selectInvoice(code) {
      this.invoiceCode = code;
      // 如果选择的发票类型和列表的相同 赋值场景类型
      this.invoiceList.forEach((item) => {
        if (code == item.Code) {
          this.sceneList = item.Childs;
          this.sceneCode = item.Childs[0].Code;
        }
      });
      this.getInvoiceData();
    },
    // 选择场景
    selectScene(code) {
      this.sceneCode = code;
      this.getInvoiceData();
    },
    // 获取发票场景类型
    getSceneType() {
      getSceneType().then((res) => {
        this.invoiceList = res.data;
        this.invoiceCode = res.data[0].Code;
        if (res.data[0].Childs) {
          this.sceneList = res.data[0].Childs;
          this.sceneCode = res.data[0].Childs[0].Code;
          this.getInvoiceData();
        }
      });
    },
    // 获取车辆报表
    getVehicleNum() {
      getVehicleNum().then((res) => {
        this.carData = res.data;
        this.carTotal = 0;
        this.carData.forEach((element) => {
          this.carTotal += Number(element.Val);
        });
      });
    },
    // 获取司机报表
    getDriverNum() {
      getDriverNum().then((res) => {
        this.driverData = res.data;

        this.driverTotal = 0;
        this.driverData.forEach((element) => {
          this.driverTotal += Number(element.Val);
        });
      });
    },

    // 船东数量报表
    getShipOwnerNum() {
      getShipOwnerNum().then((res) => {
        this.shipOwnerData = res.data;

        this.shipOwnerTotal = 0;
        this.shipOwnerData.forEach((element) => {
          this.shipOwnerTotal += Number(element.Val);
        });
      });
    },

    // 船舶数量报表
    getShipNum() {
      getShipNum().then((res) => {
        this.shipData = res.data;

        this.shipTotal = 0;
        this.shipData.forEach((element) => {
          this.shipTotal += Number(element.Val);
        });
      });
    },

    // 历史数据
    taskSummary() {
      let data = {
        InvoiceNumber: "",
        StartDatetime: "",
        InvoiceType: "",
        EnterpriseFullName: "",
        RoleType: 1,
      };
      taskSummary({ Json: JSON.stringify(data) }).then((res) => {
        this.historyData = res.data;
        this.historyData.sumTransportCosts = _formatMoney(
          this.historyData.TotalFreight
        );
        this.historyData.sumExpensesOfTaxation = _formatMoney(
          this.historyData.TotalExpensesOfTaxation
        );
        this.historyData.sumNeedPayMoney = _formatMoney(
          this.historyData.TotalAmount
        );
      });
    },
    // 点击列表去其他页面
    toPage(index) {
      if (index == 1) {
        this.$router.push("/power/userConfig/index");
      } else if (index == 2) {
        this.$router.push("/taxrelated/taxinfo/index");
      } else if (index == 3) {
        this.$router.push("/finance/payment/index");
      } else if (index == 4) {
        this.$router.push("/power/personManage/index");
      } else if (index == 5) {
        this.$router.push("/platform/Waybill/index");
      }
    },
    // 扁平化数组
    getArr() {
      let arr = [
        {
          id: 1,
          label: "一级 1",
          children: [
            {
              id: 4,
              label: "二级 1-1",
              children: [
                {
                  id: 9,
                  label: "三级 1-1-1",
                },
                {
                  id: 10,
                  label: "三级 1-1-2",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: "一级 2",
          children: [
            {
              id: 5,
              label: "二级 2-1",
            },
            {
              id: 6,
              label: "二级 2-2",
            },
          ],
        },
        {
          id: 3,
          label: "一级 3",
          children: [
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
          ],
        },
      ];
      let data = [];
      this.treeForeach(arr, (node) => {
        data.push(node);
      });
    },
    // 广度优先扁平化tree
    treeForeach(tree, func) {
      let node,
        list = [...tree];
      while ((node = list.shift())) {
        // 先查找数组中第一层追加到数组中
        func(node);
        // 再查找数组中的子节点，如果有 则添加到list里
        node.children && list.push(...node.children);
      }
    },
  },
  components: {
    LineChart,
    PieChart,
    ZXmap,
    //   HomeTip
  },
};
</script>
<style lang="scss">
.totalCmpt {
  .el-image {
    overflow: inherit !important;
  }
}
</style>
<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";

.totalCmpt {
  .chart {
    width: 100%;
  }

  .pieChart {
    width: 200px;
  }

  .tu {
    display: flex;
    align-items: center;
  }

  .enter {
    transform: translateY(-6px);
    transition: all 0.3s;
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.16);
  }

  .navBar {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-bottom: 10px;
    background: #fff;

    .el-image {
      flex: 1;
      //  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
    }

    .nav-item {
      border-radius: 8px;
      margin: 0 1.25rem /* 20/16 */;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
        transition: all 0.3s;
      }
    }
  }

  .middle-box {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    .pieData {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .top {
      position: relative;
      background: #ffffff;
      // box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 0px 10px;
      flex: 1;

      .title {
        color: #333333;
        position: relative;
        text-indent: 10px;
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 8px;
        padding-top: 1rem;
        border-bottom: 1px solid $borderGray;

        &::after {
          content: "";
          position: absolute;
          top: 56%;
          left: 0px;
          transform: translateY(-50%);
          width: 3px;
          height: 14px;
          border-radius: 5px;
          background: $primary;
        }
      }
    }

    .bottom {
      position: relative;
      background: #ffffff;

      border-radius: 4px;
      padding: 0 10px;
      margin: 0 1rem;
      flex: 1;

      .title {
        color: #333333;
        position: relative;
        text-indent: 10px;
        font-size: 14px;
        border-bottom: 1px solid $borderGray;
        font-weight: bold;
        padding-bottom: 8px;
        padding-top: 1rem;

        &::after {
          content: "";
          position: absolute;
          top: 56%;
          left: 0px;
          transform: translateY(-50%);
          width: 3px;
          height: 14px;
          border-radius: 5px;
          background: $primary;
        }
      }
    }

    .historyData {
      position: relative;
      background: #ffffff;
      border-radius: 4px;
      padding: 0 10px;
      flex: 1;

      .title {
        color: #333333;
        position: relative;
        text-indent: 10px;
        font-size: 14px;
        border-bottom: 1px solid $borderGray;
        font-weight: bold;
        padding-bottom: 8px;
        padding-top: 1rem;

        &::after {
          content: "";
          position: absolute;
          top: 56%;
          left: 0px;
          transform: translateY(-50%);
          width: 3px;
          height: 14px;
          border-radius: 5px;
          background: $primary;
        }
      }

      .data {
        // display: flex;
        // justify-content: center;
        // flex-direction: column;
        .items {
          display: flex;
          align-items: center;
          flex: 1;
          margin-bottom: 0.5rem;
          padding-left: 25%;

          .num {
            color: #ff8b17;
            font-size: 16px;
            margin-top: 3px /* 3/16 */ /* 10/16 */;
          }

          .name {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            white-space: nowrap;
            width: 8.75rem /* 140/16 */;
            text-align: right;
            margin-right: 8px;
          }
        }
      }
    }

    .tip {
      font-size: 16px !important;

      .car {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        color: #333;
        white-space: nowrap;
        font-size: 1rem;
      }

      .img {
        width: 1.75rem;
        height: 1.75rem /* 28/16 */;
        margin-right: 5px;
      }
    }
  }

  .graphic {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    border-radius: 4px;
    padding: 0 10px;
    position: relative;

    .invoice-tabs {
      display: flex;

      div {
        margin-left: 16px;
        cursor: pointer;
        padding-bottom: 4px;
        font-weight: bold;
      }

      .isActive {
        color: #409eff;
        border-bottom: 1px solid #409eff;
      }
    }

    .title {
      color: #333333;
      position: relative;
      text-indent: 10px;
      font-size: 14px;
      font-weight: bold;
      padding-bottom: 4px;

      &::after {
        content: "";
        position: absolute;
        top: 42%;
        left: 0px;
        transform: translateY(-50%);
        width: 3px;
        height: 14px;
        border-radius: 5px;
        background: $primary;
      }
    }

    .time {
      margin-bottom: 42px;
      display: flex;
      justify-content: center;
      font-size: 24px;
      font-weight: 400;

      .invoiceBtn {
        border-radius: 12px;
        // padding: 8px 20px;
        font-size: 24px;
        height: 50px;
        line-height: 50px;
        width: 176px;
        margin-bottom: 10px;
        cursor: pointer;
        margin-right: 56px;
      }

      span {
        display: inline-block;
        width: 98px;
        white-space: nowrap;
        border-radius: 6px;
        text-align: center;
        margin-right: 48px;
        font-size: 16px;
        background: #fff;
        height: 30px;
        cursor: pointer;
        line-height: 30px;
        color: #2081ff;
        border: 2px solid #2081ff;
      }

      .active {
        background: #2081ff;
        color: #fff;
      }
    }
  }
}
</style>
